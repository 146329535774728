<template>
  <div>
    <v-toolbar flat color="white">
      <v-toolbar-title class="dodo">أقســام ألمقالات</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
       <v-flex xs12 sm4>
 
      
                   <v-select
                  :items="cats_positions" 
                  v-model="editedItem.cat_pos_id"
                  label="Select"
                  single-line
                  item-text="name"
                  item-value="id"
                  return-object
                  persistent-hint
                  v-on:input="cats_filter(`${editedItem.cat_pos_id.id}`)"
                 
                ></v-select>
      </v-flex>

     
       <v-spacer></v-spacer>
      <v-dialog v-model="dialog" persistent max-width="900px">
        <v-btn slot="activator" color="primary" @click="dialog=true" dark class="dodo mb-2" >قسم جديد</v-btn>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                  
                <v-flex xs12 sm6 md12>
                  <v-text-field 
                  v-model="editedItem.cat_name" 
                  class="form_label" label="العنوان"></v-text-field>
                </v-flex>


                 <v-flex xs12 sm6 md6>
                 <v-select
                      v-model="editedItem.cats_position_id"
                      :items="cats_positions"
                      item-text="name"
                      item-value="id"
                      label="موقع القسم في الصفحه الرئسية"
    
                  ></v-select>
  
                </v-flex>
 
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" flat @click="save" class="dodo">حفـــظ</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-data-table 
      :headers="headers"
      :items="cats"
      class="elevation-1"
      :items-per-page="15"
      hide-default-footer
  disable-pagination
      
    >

    <template v-slot:[`item.actions`]="{ item }">

<v-icon
    small
    class="mr-2"
    @click="editItem(item)"
  >
  تعديل
  </v-icon>

  |
  <v-icon
    small
    @click="deleteItem(item)"
  >
    حذف
  </v-icon>
  


</template>
      <template slot="no-data">
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>


    <v-pagination class="pagination" total-visible="20" v-model="page"
      color="#c7000b" style="position: relative; top: 20px;" circle=""
      :length="pageCount">
    </v-pagination>



  </div>
</template>


<script>
   import axios from 'axios';
   import swal from 'sweetalert2';
  export default {
    data  () {
      return {
      attachments:[],
      form: new FormData,  
      dialog: false,
      cats_positions:[],
      page: 1,
        pageCount: 0,
        current_page: 1,
        last_page: 0,
	  imageFile: '',
      arts:[],
      view_ats:[],
      cats:[],
      headers: [

        { text: 'أسم ألقسم ', value: 'cat_name',sortable: false,},
        { text:'موقع القسم', value: 'position_name' },
        { text: 'Actions', value: 'actions', sortable: false } 

      ],
       data:'',
      desserts: [],
      editedIndex: -1,
      editedItem: {

      },
      defaultItem: {
     
      },
   

    }},

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'أضافه  قسم جديد' : 'تعديل القسم'
      },selected: function () {
        return this.getMoreitems();
      }
    },

    watch: {
      selected: 'search by sub_cat_id',
    },
      dialog (val) {
        val || this.close()
      },
    

    created () {

       Fire.$on('cats_fliter',() => {
         
             axios.get('cat_by_positionid/'+this.editedItem.cat_pos_id.id).then(response => (this.cats = response.data)) 
            
        });



    this.cat_position()
       this.initialize()
        

       
     
      
    },

    methods: {
      getMoreitems() {
        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          this.initialize();

        }
      },
           pickFile () {
            this.$refs.image.click ()
        },


          pickFiles () {
            this.$refs.images.click ()
            console.log('h1');
         },


        onFilesPicked (e) {

            let selectedFiles=e.target.files;
                if(!selectedFiles.length){
                    return false;
                }
                for(let i=0;i<selectedFiles.length;i++){
                    this.attachments.push(selectedFiles[i]);
                }
  

        },
		
		onFilePicked (e) {
    
       
      const files = e.target.files
           
			if(files[0] !== undefined) {
  
        this.imageName = files[0].name
       // console.log( this.imageName );

				if(this.imageName.lastIndexOf('.') <= 0) {
					return
        }
        

				const fr = new FileReader ()
				fr.readAsDataURL(files[0])
				fr.addEventListener('load', () => {
         
					this.imageUrl = fr.result
          this.imageFile = files[0] // this is an image file that can be sent to server...

    
				})
			} else {
				this.imageName = ''
				this.imageFile = ''
        this.imageUrl = ''
        
        
			}
    },
    
     cats_filter(){
     console.log(this.editedItem.cat_pos_id.id)
     //  console.log(this.editedItem.cat_pos_id);
        Fire.$emit('cats_fliter');
      },

      initialize () {    
                // axios.get('cats').then(response => ({
                //   this.cats = response.data;
                //   
                // })) ;


                
                axios.get('cats?page=' + this.current_page,{
                headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + this.$store.state.AdminInfo.token
            }
          })

     
        
        .then(d => {
          //console.log( d.data);
          this.cats = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })




      },

      cat_position(){
             axios.get('cats_positions').then(response => (this.cats_positions = response.data)) 
      }
,
    
      editItem (item) {

       this.editedIndex = this.cats.indexOf(item)
       this.editedItem = Object.assign({}, item)
     
        this.dialog = true

            if(this.imageUrl[0]==null)
        {

            this.img_old= this.editedItem.main_img;
            this.imageUrl='img_art/'+this.img_old;
           
        }
      },

      deleteItem (item) {
       
        
        const index = this.desserts.indexOf(item)
        //confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)


        this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        // Send request to the server
                         if (result.value) {
      




                                axios.delete('cat_deleted/' + item.id, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              },
            }).then(() => {
              this.$swal.fire('Successfully', 'done', "success");
              this.initialize()
            }).catch(() => {
              this.$swal("Failed!", "There was something wronge.", "warning");
            });
                         }
                    })

                    

      },

      close () {
        this.dialog = false
        this.imageUrl='';
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        

        if (this.editedIndex > -1) { 

               
           axios.post('cat_update/'+this.editedItem.id,  {
                     cat_name:this.editedItem.cat_name,
                     cats_position_id:this.editedItem.cats_position_id,				
            },  {
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  }
            
            );


            this.initialize ();
            
        }
        
        else {

                axios.post('cats', {             
               cat_name:this.editedItem.cat_name,
               cats_position_id:this.editedItem.cats_position_id,
          
            }
            , {
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  }
            
            ).then(() => {

					    this.dialog = false
               Fire.$emit('AfterCreate');
               toast({
                  type: 'success',
                  title: 'Signed in successfully'
               })

               //Send Photo To Server

              
            .catch(() => { });
              
				   }).catch(() => {});
             this.initialize ();
             
        }
        this.close()
      }
    },

         mounted () {
            this.initialize ();
        
         }

  }
</script>