<template>
    <div>
        <v-toolbar flat color="white">
            <v-toolbar-title class="dodo">ألكتـــاب</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-divider class="mx-2" inset vertical></v-divider>




            <v-select v-model="editedItem.writers_filter_id" :items="writers_type" item-text="name" single-line
                item-value="id" label="أختار نوع الكاتب" return-object persistent-hint
                v-on:input="writers_filter(`${editedItem.writers_filter_id.id}`)"></v-select>

            <v-spacer></v-spacer>






            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" persistent max-width="770px">
                <v-btn slot="activator" @click="dialog=true" color="primary" dark class="dodo mb-2">اضافه كاتب جديد</v-btn>
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container grid-list-md>

                            <v-form v-model="valid" ref="form">


                                <v-flex xs12 sm6 md6></v-flex>



                                <v-flex xs12 sm6 md12>
                                    <v-text-field v-model="editedItem.name" class="form_label" required
                                        :rules="nameRules" label="اسم الكاتب"></v-text-field>
                                </v-flex>


                                <v-flex xs12 sm6 md12>
                                    <v-text-field v-model="editedItem.email" class="form_label"
                                        v-validate="'required|email'" data-vv-name="email" :rules="emailRules"
                                        label="الايميل"></v-text-field>
                                </v-flex>


                                <v-flex xs12 sm6 md12>
                                    <v-text-field v-model="editedItem.phone" class="form_label" label="رقم الهاتف">
                                    </v-text-field>
                                </v-flex>



                                <v-flex xs12 sm6 md12>
                                    <v-textarea v-model="editedItem.curriculum_vitae" class="form_label" label="السيره الذاتيه">
                                    </v-textarea>
                                </v-flex>


                                





                            </v-form>


                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" class="dodo" flat @click="close">خروج</v-btn>
                        <v-btn color="blue darken-1" class="dodo" flat @click="save">حفظ</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        <v-data-table :headers="headers" :items="writers" :items-per-page="15" class="elevation-1" hide-default-footer
  disable-pagination>
            <template slot="items" slot-scope="props">

                <td class="text-xs-right">{{props.item.id}}</td>
                <td class="text-xs-right">{{ props.item.name }}</td>
                <td class="text-xs-right">{{props.item.email}}</td>
                <td class="text-xs-right">{{props.item.phone}}</td>
                <td class="text-xs-right">{{props.item.writers_type_name}}

                </td>

                <!-- <td class="justify-center layout px-0">
                    <v-icon small class="mr-2" @click="editItem(props.item)">
                        edit
                    </v-icon>
                    <v-icon small @click="deleteItem(props.item)">
                        delete
                    </v-icon>
                </td> -->
            </template>
            <template slot="no-data">
                <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>


            <template v-slot:[`item.actions`]="{ item }">

<v-icon
    small
    class="mr-2"
    @click="editItem(item)"
  >
  تعديل
  </v-icon>

  |
  <v-icon
    small
    @click="deleteItem(item)"
  >
    حذف
  </v-icon>
  


</template>
        </v-data-table>

        <v-pagination class="pagination" total-visible="20" v-model="page"
      color="#c7000b" style="position: relative; top: 20px;" circle=""
      :length="pageCount">
    </v-pagination>
    </div>
</template>


<script>
 import axios from 'axios';
import swal from 'sweetalert2';
    export default {
        data() {
            return {
                image_user: [],
                valid: false,
                name: 'Default',
                nameRules: [
                    (v) => !!v || 'يجب ادخال الاسم',

                ],
                email: '',
                emailRules: [
                    // (v) => !!v || 'يجب ادخال الايميل ',
                    // (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'ألايميل غير صحيح'
                ],

                attachments: [],
                writers_type: [],
                radios: 'radio-1',
                form: new FormData,
                dialog: false,
                imagesUrl: '',
                imageName: '',
                img_old: '',
                page: 1,
        pageCount: 0,
        current_page: 1,
        last_page: 0,
                article_types: [],
                imagesName: ' ',
                search: null,
                filez: [],
                arts_status: [],
                imageFile: [],
                imageUrl: '',
                journal_nums: [],
                imageFile: '',
                writers: [],
                cats: [],
                headers: [


                    {
                        text: 'ID',
                        align: 'left',
                        value: 'id',
                        sortable: false
                    },
                    {
                        text: 'أسم الكاتب',
                        align: 'left',
                        value: 'name',
                        sortable: false
                    },
                    {
                        text: 'ألايميل',
                        value: 'email',
                        sortable: false,
                    },
                    {
                        text: 'ألهاتف',
                        value: 'phone',
                        sortable: false,
                    },
                    {
                        text: 'نوع الكاتب',
                        value: 'writers_type_name',
                        sortable: false,
                    },
                    {
                        text: 'Actions',
                        value: 'actions',
                        sortable: false,
                        sortable: false,
                    }

                ],
                data: '',
                desserts: [],
                editedIndex: -1,
                editedItem: {
                    name: '',

                    article_types: [],
                    email: '',
                    phone: '',
                    file_name: '',
                    password: '',
                    state_id: '',
                },
                defaultItem: {
                    name: '',
                    email: '',
                    phone: '',
                    password: '',
                    file_name: '',
                    state_id: '1',

                },
                def: {
                    name: 'sds',


                }

            }
        },


        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'أضافه كاتب جديد' : 'تعديل معلومات كاتب'
            },selected: function () {
        return this.getMoreitems();
      }
        },

          watch: {

      selected: 'search by sub_cat_id',

    },
    
        pickImg_user() {
            this.$refs.image_user.click()
        },
        onPickImg_user(e) {

            let img_user = e.target.files;
            this.img_user = img_user[0]
            this.img_userName = img_user[0].name
        },
        dialog(val) {
            val || this.close()
        },


        created() {
            console.log(this.editedItem.writers_filter_id);
            Fire.$on('writers_filter', () => {

                axios.get('writers_type_fliter/' + this.editedItem.writers_filter_id.id).then(response => (
                    this.writers = response.data))

            });

            this.writer_type()
            this.initialize()


        },

        methods: {
            getMoreitems() {
        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          this.initialize();

        }
      },
            pickFile() {
                this.$refs.image.click()
            },

            JournalNumber() {

                axios.get('journal_num').then(response => (this.journal_nums = response.data))

            },

            pickFiles() {
                this.$refs.images.click()
                console.log('h1');
            },


            onFilesPicked(e) {

                let selectedFiles = e.target.files;
                if (!selectedFiles.length) {
                    return false;
                }
                for (let i = 0; i < selectedFiles.length; i++) {
                    this.attachments.push(selectedFiles[i]);
                }



            },

            onFilePicked(e) {


                const files = e.target.files


                if (files[0] !== undefined) {



                    this.imageName = files[0].name
                    // console.log( this.imageName );

                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }


                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {

                        this.imageUrl = fr.result
                        this.imageFile = files[0] // this is an image file that can be sent to server...








                    })
                } else {
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''


                }
            },



            writers_filter() {


                Fire.$emit('writers_filter');
            },

            initialize() {

                axios.get('writers?page=' + this.current_page,{
                headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizationss: "Bearer " + this.$store.state.AdminInfo.token
            }
          })

     
        
        .then(d => {
          this.writers = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })

               
            },

            writer_type() {
                axios.get('writers_type').then(response => (this.writers_type = response.data))
            },

            article_type() {
                axios.get('article_types').then(response => (this.article_types = response.data))
            },

            JournalNumber() {

                axios.get('journal_num').then(response => (this.journal_nums = response.data))

            },

            categories() {
                axios.get('cats').then(response => (this.cats = response.data))
            },



            editItem(item) {




                this.editedIndex = this.writers.indexOf(item)
                this.editedItem = Object.assign({}, item)

                this.dialog = true

                if (this.imageUrl[0] == null) {

                    this.img_old = this.editedItem.user_img;
                    this.imageUrl = 'https://alkafeel.net/sadda/writers_img/thumb/' + this.img_old;

                }
            },
        

            deleteItem(item) {


                const index = this.desserts.indexOf(item)
                //confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        // Send request to the server
                         if (result.value) {
                            axios.post('write_deleted/' + item.id, {},{
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  }).then(() => {
                                  this.$swal.fire('Successfully', 'done', "success");
                                     this.initialize()
                                }).catch(()=> {
                                    this.$swal.fire("Failed!", "There was something wronge.", "warning");
                                });
                         }
                    })



             



            },

            close() {
                  this.imageUrl = '';
                this.dialog = false
              
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                }, 300)
            },
            close() {
 this.imageUrl = '';
                this.$refs.form.reset()
                this.dialog = false

            },








            save() {


                //edite
                if (this.editedIndex > -1) {


                    if (this.$refs.form.validate()) {
                        axios.post('write_update/' + this.editedItem.id, {
                            name: this.editedItem.name,
                            phone: this.editedItem.phone,
                            email: this.editedItem.email,
                            writer_type_id: 1,
                            curriculum_vitae:this.editedItem.curriculum_vitae

                        }, {
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  }).then(() => {

                      // Update Image_User FILE 
                    //     if (this.imageFile !== null) {
                    //         let formDataImg_user = new FormData();
                    //         formDataImg_user.append('img_user',this.imageFile);
                    //         axios.post('update_writer_img/'+this.editedItem.id,
                    //             formDataImg_user, {
                    //                 headers: {
                    //                     'Content-Type': 'multipart/form-data',
                    //                     Accept: "application/json",
                    //   Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    //                 }
                    //             }
                    //         )
                    //     }

                    this.$swal.fire('Successfully', 'done', "success");

                            this.initialize();

                            this.close();

                        }).catch(() => {

                        });;
                    }



                }

                //save 
                else {



                    if (this.$refs.form.validate()) {

                        axios.post('writers', {
                            name: this.editedItem.name,
                            phone: this.editedItem.phone,
                            email: this.editedItem.email,
                            writer_type_id: 1,
                            curriculum_vitae:this.editedItem.curriculum_vitae
                        }, {
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  }).then(() => {

                            
                        // Uploude Image_User FILE 
                    //     if (this.imageFile !== null) {
                    //         let formDataImg_user = new FormData();
                    //         formDataImg_user.append('img_user',this.imageFile);
                    //         axios.post('uploude_writer_img',
                    //             formDataImg_user, {
                    //                 headers: {
                    //                     'Content-Type': 'multipart/form-data',
                    //                     Accept: "application/json",
                    //   Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    //                 }
                    //             }
                    //         )
                    //     }

                    this.$swal.fire('Successfully', 'done', "success");

                            this.initialize();

                            this.close();

                        }).catch(() => {

                        });





                    }


                }


            },
        },

        mounted() {
            this.initialize();

        }

    }

</script>
